<!--
 * @Description  : 联系我们
-->
<template>
  <div class="hx-main pad-contact-us">
    <div class="pad-cover">
      <span class="text-title">
        关于我们
      </span>
      <img src="./../assets/img/img-banner-3.jpg" alt="" class="img-cover">
    </div>
    <div class="hx-container">
      <div class="hx-title">安然安全简介</div>
      <span class="hx-subtitle">ABOUT US</span>
      <article class="hx-article">
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;深圳市安然安全科技有限公司成立于2017年，是一家专业化第三方安全技术服务机构，现为深圳市政府采购中心合格供应商，长期为中海油、中交、中航、华润等单位提供服务，例如：安全管理合规性评估、安全管理体系建设、安全生产标准化建设、安全风险管控、安全检查与隐患治理、应急预案编制、应急演练、HAZOP分析及SIL评估、安全信息化平台开发、安全培训、专家驻场等服务。
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司成立以来，通过自身不懈的努力和广大客户的信赖，获得如下殊荣：“广东省守合同重信用企业 、AAA级企业信用等级证书 、深圳市科技型中小企业、ISO9001质量管理体系认证证书 、ISO45001职业健康安全管理体系认证证书 、ISO14001环境管理体系认证证书 ”
        </p>
        <certification ></certification>
        <strong class="hx-title">
          核心业务
        </strong>
        <span class="hx-subtitle">BUSINESS</span>
        <img class="img" src="./../assets/img/article/img-doc-6.jpg" alt="">
        <h3>01 HSE合规性评估</h3>
        <p>
          HSE合规性评估——依据国家安全生产方针政策、法律法规、标准及相关要求，全面评估企业的安全生产现状，客观分析其安全工作的符合性和履职情况，找出安全管理的薄弱环节和差距（Gap Analysis），提出合理化改进建议，辅导客户提升安全管理水平，规避安全生产责任风险。
        </p>
        <h3>
          02 HSE管理体系建设
        </h3>
        <p>
          2015年以来，ISO14001环境管理体系、ISO9001质量管理体系相继改版。2018年3月12日，国际标准化组织（ISO）发布了职业健康与安全的新标准ISO45001职业健康安全管理体系，该标准已替代OHSAS18001:2007职业健康安全管理体系。
  ISO45001:2018的发布，为全球提供了一个统一的职业健康安全管理体系标准，基于一个全新的框架指导企业识别与业务过程相关的职业健康安全风险，有效降低企业的安全生产风险，从而进一步保护劳动者。
  （说明：HSE管理体系是企业HSE管理工作的基础性文件和指导准则。HSE管理体系与安全生产标准化达标建设、双重预防机制的要求应有机融合，使之成为一套“简单、有效、容易落地”的行动准则，杜绝“多层皮”现象，避免给执行层带来困扰。）
        </p>
        <h3>
          03 安全风险管控辅导
        </h3>
        <p>
          安全管理的本质是风险管理！<br />
          安全管理的核心任务是预防和控制事故！
        </p>
        <img class="img" src="./../assets/img/article/img-doc-7.jpg" alt="">
        <h3>
          04 第三方安全检查（隐患排查治理）
        </h3>
        <p>
          第三方安全检查——由持证专业人员组成检查组，对安全生产现场进行巡查和评审，及时发现安全隐患，提出详细的“诊断”报告，辅导并跟踪隐患整改，实现闭环管理。
          检查类型：
        </p>
        <ul>
          <li>定期安全检查，如月度/季度;</li>
          <li>安全生产标准化年度评审 专项安全检查与整治。</li>
        </ul>
        <h3>
          05 HAZOP分析&SIL评估
        </h3>
        <p>
          HAZOP（Hazard and Operability Study，危险与可操作性分析） ， 是在设计阶段和运营阶段，根据项目的实际架构，组织来自业主和设计院等单位不同专业的工程师，使用一系列的“偏差”（比如高流量、低流量、高温、低温、高压、低压、高液位、低液位等），在HAZOP组长的引导下，全体与会人员使用“头脑风暴”的方法，系统地识别装置设计里可能存在安全或操作等问题，并提出改进意见的一种分析方法。
          SIL（Safety Integrity Level，安全完整性等级），是由风险分析计算得来，是通过分析风险暴露时间和频率、风险后果严重程度、不能避开风险的概率和不期望事件发生概率等四个因素综合得出的。
        </p>
        <h3>
          06安全技术人员驻场服务
        </h3>
        <p>
          驻场服务——我方提供具有安全资质证书的技术人员，入驻甲方指定的工作地点，与甲方一起参与日常安全事务，及时提出专业支持和合理化建议，协助甲方解决安全工作难题。
        </p>
        <img class="img" src="./../assets/img/article/img-doc-8.jpg" alt="">
        <p>
          实践证明，驻场服务可以很好地解决企业在特定领域的专业局限、短期人手不足等困境；特别适合政府行政主管部门和在建工程项目。
        </p>
        <h3>
          07安全信息化
        </h3>
        <p>
          安全信息化——为客户策划一个要素完整、程序严谨的安全管理思路，并用IT技术为客户定制开发一套安全信息化平台。此平台集指导思路、实用工具、资料查询、档案管理、数据分析等功能于一身，大大提升安全管理工作成效。
        </p>
        <img class="img" src="./../assets/img/article/img-doc-9.jpg" alt="">
      </article>
    </div>
  </div>
</template>

<script>
import certification from '../components/certification.vue'
export default {
  components: {
    certification
  },
  data () {
    return {
    }
  }
}
</script>

<style lang="less" scoped>
@import "./../assets/css/index.less";
@height-card-content: 280px;
.pad-contact-us {
  .pad-contact-content {
    display: block;
    width: 100%;
    .card-location {
      width: 100%;
      display: flex;
      background-color: #fafafa;
      transition: transform .4s;
      border-radius: 6px;
      &:hover {
        transform: scale(1.02, 1.02);
      }
      & + .card-location {
        margin-top: @pm-bg;
      }
      .text-content {
        width: 400px;
        height: @height-card-content;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        .title {
          font-size: 20px;
          font-weight: 600;
          display: block;
          margin-bottom: @pm-bg;
        }
        .row {
          & + .row {
            margin-top: @pm-md;
          }
          .label {
            width: 100%;
            display: block;
            font-size: 14px;
            color: #666;
          }
          .text {
            font-size: 16px;
            font-weight: 500;
            color: #000;
            width: 100%;
            display: block;
          }
        }
      }
      .map-location {
        flex: 1;
        height: @height-card-content;
      }
    }
  }
  .img-preview {
    transition: transform .3s;
    cursor: zoom-in;
    &:hover {
      transform: scale(1.05, 1.05);
    }
  }
  .pad-qrcodes {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: @pm-md 0 @pm-lg * 2;
    .card-qrcode {
      display: inline-flex;
      flex-direction: column;
      padding: @pm-bg @pm-bg 0;
      border-radius: @pm-sm;
      background: linear-gradient(125deg, @color-main 0%, @color-light-blue 100%);
      .img-qrcode {
        height: 200px;
        border: 4px;
      }
      .text {
        display: block;
        width: 100%;
        line-height: 54px;
        text-align: center;
        color: white;
      }
      & + .card-qrcode {
        margin-left: @pm-lg * 2;
      }
    }
  }
}

@media screen and (min-width: 720px) {
  .pad-contact-content {
    padding: @pm-lg @pm-bg;
    .card-location {
      padding: @pm-bg;
    }
  }
  .hx-article {
    padding: @pm-bg 0!important;
  }
}

@media screen and (max-width: 720px) {
  .pad-contact-content {
    padding: @pm-sm!important;
    .card-location {
      padding: @pm-sm;
    }
  }
}
</style>
